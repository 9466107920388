













































































































































import { Vue, Component } from "vue-property-decorator";
import api from "@/api";


@Component({
  name: "couponAgentD",
  components: {},
})
export default class extends Vue {
  private tableLoading = false;
  private search: any = {
    timeSpan: 3,
    pagination: {
      page: 0,
      pageSize: 20
    }
  };
  private config: any = {
    outorg:[],
    trade:{},
    coupon: {
      "xjq": "现金券",
      "mjq": "满减券",
      "zkq": "折扣券",
      "jfq": "积分券",
      "jfqr": "兑换券"
    }
  };

  activated() {
    this.getAgent()
    this.searchTable()
    this.getConfig()
  }

  // 召回
  btnLoading = false
  recallone(row: any) {
    this.btnLoading = true
    api
      .post('J27324', {brokerId: this.$route.query.id, couponNumber: row.couponNumber})
      .then(res => {
        this.$message.success('操作成功')
        this.searchTable();
        this.getAgent()
      }).finally(() => {
        this.btnLoading = false
    })
  }

  agentTable = []
  getAgent() {
    api
        .post('J27319', {id: this.$route.query.id})
        .then(res => {
          // this.total = res.total;
          this.agentTable = res.list;
        }).finally(() =>{
          this.tableLoading = false;
        })
  }

  /**字典 */
  protected async getConfig() {
    api
        .post('A23005')
        .then(res => {
          this.config.outorg = res.list

        })
    api.post('A23006')
      .then(res => {
        this.config.trade = res.list
      })
  }

  // 搜索 search:true 重置翻页
  loading = true
  total = 0
  private tableData:any = []
  searchTable(search = false) {
    this.loading = true;
    if (search) {
      this.search.pagination = {
        pageSize: 20,
        page: 0,
      };
    }
    api
        .post('J27323', {brokerId: this.$route.query.id, ...this.search})
        .then(res => {
          this.total = res.total;
          this.tableData = res.list;
        }).finally(() =>{
          this.loading = false;
        })
  }


  protected changeDate(e:any) {
    this.searchTable()
  }







}
